/**
 * Define the Route List items
 */
import React from 'react';
import NoPermission from '../Modules/Core/NoPermission/NoPermission';

import Splash from '../Modules/Core/Login/Pages/Splash';
import AzureRedirectComponent from '../Modules/Core/Login/Pages/AzureRedirectComponent';
import SocialMediaRedirectComponent from '../Modules/Core/Login/Pages/SocialMediaRedirectComponent';
import Register from '../Modules/Core/Login/Pages/Register';
import NewPassword from '../Modules/Core/Login/Pages/NewPassword';
import ForgotPassword from '../Modules/Core/Login/Pages/ForgotPassword';
import Autest from '../assets/images/side-menu/icon_autest.svg';
import CommandIcon from '../assets/images/side-menu/icon_command_maintainance.svg';
import DeviceEnrollmentIcon from '../assets/images/side-menu/icon_device_enrollment.svg';
import ExecutionIcon from '../assets/images/side-menu/icon_execution.svg';
import GlobalIcon from '../assets/images/side-menu/icon_global.svg';
import OrganizationIcon from '../assets/images/side-menu/icon_orgainsation.svg';
import ProductsIcon from '../assets/images/side-menu/icon_products.svg';
import ProfileIcon from '../assets/images/side-menu/icon_profiles.svg';
import RFIcon from '../assets/images/side-menu/icon_rf_screens.svg';
import RunsetIcon from '../assets/images/side-menu/icon_runset.svg';
import RuntimeIcon from '../assets/images/side-menu/icon_runtime.svg';
import TestCasesIcon from '../assets/images/side-menu/icon_test cases.svg';
import TestIcon from '../assets/images/side-menu/icon_test.svg';
import UserIcon from '../assets/images/side-menu/icon_users.svg';
import FieldInfoIcon from '../assets/images/side-menu/icon_field_info.svg';
import FormInfoIcon from '../assets/images/side-menu/icon_form_info.svg';
import Products from '../Modules/Products/Pages/Products';
import LoadCommands from '../Modules/Core/Command/Pages/Commands';
import Tests from '../Modules/Autest/Test/Pages/Tests';
import AddTest from '../Modules/Autest/Test/Pages/AddTest';
import AddCommand from '../Modules/Core/Command/Pages/AddCommand';
import UserTenant from '../Modules/Core/Login/Pages/UserTenant';
import TestCases from '../Modules/Autest/TestCases/Pages/TestCases';
import AddTestCase from '../Modules/Autest/TestCases/Pages/AddTestCase';
import DeviceEnrollment from '../Modules/SmartRF/DeviceEnrollment/Pages/DeviceEnrollment';
import RemoteServers from '../Modules/Autest/RemoteServer/Pages/RemoteServers';
import AddRemoteServer from '../Modules/Autest/RemoteServer/Pages/AddRemoteServer';
import RunSets from '../Modules/Autest/RunSet/Pages/RunSets';
import AddRunSet from '../Modules/Autest/RunSet/Pages/AddRunSet';
import Profile from '../Modules/Core/Profile/Profile';
import Executions from '../Modules/Autest/Execution/Pages/Executions';
import ExecutionDetail from '../Modules/Autest/ExcecutionDetail/Pages/ExecutionDetail';
import AddRFProfile from '../Modules/SmartRF/RFProfile/Pages/AddRFProfile';
import RFProfileGroup from '../Modules/SmartRF/RFProfileGroup/Pages/RFProfileGroup';
import AddRFProfileGroup from '../Modules/SmartRF/RFProfileGroup/Pages/AddRFProfileGroup';
import Organization from '../Modules/Organization/OrganizationDetail/Organization';
import RFFormInfo from '../Modules/SmartRF/RFFormInfo/Pages/RFFormInfo';
import AddRFFormInfo from '../Modules/SmartRF/RFFormInfo/Pages/AddRFFormInfo';
import RFFieldInfo from '../Modules/SmartRF/RFFieldInfo/Pages/RFFieldInfo';
import AddRFFieldInfo from '../Modules/SmartRF/RFFieldInfo/Pages/AddRFFieldInfo';
import OrganizationProducts from '../Modules/Organization/OrganizationProducts/Pages/OrganizationProducts';
import OrganizationUser from '../Modules/Organization/OrganizationUsers/Pages/OrganizationUser';
import PasswordChange from '../Modules/Core/PasswordChange/PasswordChange';
import AppFlow from '../Modules/Autest/AppFlow/Pages/AppFlow';
import AddAppFlow from '../Modules/Autest/AppFlow/Pages/AddAppFlow';
import AutestDashboard from '../Modules/Autest/Dashboard/Pages/AutestDashboard';
import RFDashboard from '../Modules/SmartRF/Dashboard/Pages/SmartRFDashboard';
import AdminOrganization from '../Modules/Admin/Organization/Pages/Organization';
import AddOrganization from '../Modules/Admin/Organization/Pages/AddOrganization';
import { AUTH_PERMISSION } from '../constants/applicationConstants';

const RoutesItemsList = [
  {
    text: '',
    icon: '',
    route: '/login',
    element: <Splash />,
    layouts: 'EmptyLayout',
    is_drawer_item: 'false',
    page_title: 'Login',
    isauth: false,
    is_mobile: 'true',
  },
  {
    text: '',
    icon: '',
    route: '/user/tenant',
    element: <UserTenant />,
    layouts: 'EmptyLayout',
    is_drawer_item: 'false',
    page_title: 'Login',
    isauth: true,
    is_mobile: 'true',
  },
  {
    text: '',
    icon: '',
    route: '/register',
    element: <Register />,
    layouts: 'EmptyLayout',
    is_drawer_item: 'false',
    page_title: 'Register',
    isauth: false,
    is_mobile: 'true',
  },
  {
    text: '',
    icon: '',
    route: '/new-password/:token?',
    element: <NewPassword />,
    layouts: 'EmptyLayout',
    is_drawer_item: 'false',
    page_title: 'New Password',
    isauth: false,
    is_mobile: 'true',
  },
  {
    text: '',
    icon: '',
    route: '/forgot-password',
    element: <ForgotPassword />,
    layouts: 'EmptyLayout',
    is_drawer_item: 'false',
    page_title: 'Forgot Password',
    isauth: false,
    is_mobile: 'true',
  },
  {
    text: 'Products',
    icon: '',
    route: '/',
    element: <Products />,
    layouts: 'CardLayout',
    is_drawer_item: 'false',
    page_title: 'Products',
    isauth: true,
    is_mobile: 'true',
    iconsvg: ProductsIcon,
  },
  {
    id: 3,
    text: 'Products',
    icon: '',
    route: '/products',
    element: <Products />,
    layouts: 'CardLayout',
    is_drawer_item: 'true',
    page_title: 'Products',
    isauth: true,
    is_mobile: 'true',
    iconsvg: ProductsIcon,
  },
  {
    id: 25,
    text: 'Profile',
    icon: '',
    route: '/profile',
    element: <Profile />,
    layouts: 'TileLayout',
    is_drawer_item: 'false',
    page_title: 'Profile',
    isauth: true,
    is_mobile: 'true',
    iconsvg: ProfileIcon,
  },
  {
    text: 'No Permission',
    route: '/no-permission',
    element: <NoPermission />,
    layouts: 'TileLayout',
    is_drawer_item: 'false',
    is_mobile: 'false',
  },
  {
    text: 'AuTest',
    icon: 'k-i-tell-a-friend',
    element: <AutestDashboard />,
    route: '/autest/overview',
    'data-expanded': false,
    id: 4,
    layouts: 'TileLayout',
    is_drawer_item: 'true',
    isauth: false,
    is_mobile: 'true',
    iconsvg: Autest,
    AuthPermission: 'Autest',
  },
  {
    text: 'Global',
    icon: 'k-i-minus',
    id: 5,
    parentid: 4,
    'data-expanded': false,
    layouts: 'CardLayout',
    page_title: 'Global',
    is_drawer_item: 'true',
    isauth: false,
    is_mobile: 'true',
    iconsvg: GlobalIcon,
  },
  {
    text: 'Application Flow',
    icon: 'k-i-parameters',
    id: 10,
    parentid: 5,
    route: '/autest/application-flow',
    layouts: 'CardLayout',
    element: <AppFlow />,
    page_title: 'Application Flow',
    is_drawer_item: 'true',
    isauth: true,
    is_mobile: 'true',
    iconsvg: TestIcon,
  },
  {
    text: 'Command Maintenance',
    icon: 'k-i-minus',
    id: 11,
    parentid: 5,
    route: '/autest/commands',
    layouts: 'CardLayout',
    element: <LoadCommands />,
    page_title: 'Command Maintenance',
    is_drawer_item: 'true',
    isauth: true,
    is_mobile: 'true',
    iconsvg: CommandIcon,
  },
  {
    text: 'Test',
    icon: 'k-i-minus',
    id: 12,
    parentid: 5,
    route: '/autest/tests',
    layouts: 'CardLayout',
    element: <Tests />,
    page_title: 'Test',
    is_drawer_item: 'true',
    isauth: true,
    is_mobile: 'true',
    iconsvg: TestIcon,
  },
  {
    text: 'AddAppFlow',
    icon: 'k-i-minus',
    id: 25,
    parentid: 5,
    route: '/autest/flow',
    layouts: 'TileLayout',
    element: <AddAppFlow />,
    page_title: 'Add Flow',
    is_drawer_item: 'false',
    isauth: true,
    is_mobile: 'true',
  },
  {
    text: 'AddTest',
    icon: 'k-i-minus',
    id: 13,
    parentid: 5,
    route: '/autest/test',
    layouts: 'TileLayout',
    element: <AddTest />,
    page_title: 'Add Test',
    is_drawer_item: 'false',
    isauth: true,
    is_mobile: 'true',
  },
  {
    text: 'AddLCS',
    icon: 'k-i-minus',
    id: 13,
    parentid: 5,
    route: '/autest/command',
    layouts: 'TileLayout',
    element: <AddCommand />,
    page_title: 'Add Command',
    is_drawer_item: 'false',
    isauth: true,
    is_mobile: 'true',
  },
  {
    text: 'Test Case',
    icon: 'k-i-minus',
    id: 16,
    parentid: 5,
    route: '/autest/testcases',
    layouts: 'CardLayout',
    element: <TestCases />,
    page_title: 'Test Case',
    is_drawer_item: 'true',
    isauth: true,
    is_mobile: 'true',
    iconsvg: TestCasesIcon,
  },
  {
    text: 'Add Test Case',
    icon: 'k-i-minus',
    id: 12,
    parentid: 5,
    route: '/autest/testcase',
    layouts: 'TileLayout',
    element: <AddTestCase />,
    page_title: 'Add Test Case',
    is_drawer_item: 'false',
    isauth: true,
    is_mobile: 'true',
  },
  {
    text: 'Runtime',
    icon: 'k-i-minus',
    id: 6,
    parentid: 4,
    'data-expanded': false,
    layouts: 'CardLayout',
    page_title: 'Runtime',
    is_drawer_item: 'true',
    isauth: false,
    is_mobile: 'true',
    iconsvg: RuntimeIcon,
  },
  {
    text: 'Remote Server',
    icon: 'k-i-minus',
    id: 21,
    parentid: 6,
    route: '/autest/remote-servers',
    layouts: 'CardLayout',
    element: <RemoteServers />,
    page_title: 'Remote Server',
    is_drawer_item: 'false',
    isauth: true,
    is_mobile: 'false',
  },
  {
    text: 'Add Remote Server',
    icon: 'k-i-minus',
    id: 17,
    parentid: 6,
    route: '/autest/remote-server',
    layouts: 'TileLayout',
    element: <AddRemoteServer />,
    page_title: 'Add Remote Server',
    is_drawer_item: 'false',
    isauth: true,
    is_mobile: 'true',
  },
  {
    text: 'Run Set',
    icon: 'k-i-minus',
    id: 18,
    parentid: 6,
    route: '/autest/run-sets',
    layouts: 'CardLayout',
    element: <RunSets />,
    page_title: 'Run Set',
    is_drawer_item: 'true',
    isauth: true,
    is_mobile: 'true',
    iconsvg: RunsetIcon,
  },
  {
    text: 'Add Run Set',
    icon: 'k-i-minus',
    id: 19,
    parentid: 6,
    route: '/autest/run-set',
    layouts: 'TileLayout',
    element: <AddRunSet />,
    page_title: 'Add Run Set',
    is_drawer_item: 'false',
    isauth: true,
    is_mobile: 'true',
  },
  {
    text: 'Executions',
    icon: 'k-i-minus',
    id: 26,
    parentid: 4,
    route: '/autest/executions',
    layouts: 'CardLayout',
    page_title: 'Executions',
    element: <Executions />,
    is_drawer_item: 'true',
    isauth: true,
    is_mobile: 'true',
    iconsvg: ExecutionIcon,
  },
  {
    text: 'Execution Detail',
    icon: 'k-i-minus',
    id: 27,
    parentid: 4,
    route: '/autest/execution',
    layouts: 'TileLayout',
    page_title: 'Execution Detail',
    element: <ExecutionDetail />,
    is_drawer_item: 'false',
    isauth: true,
    is_mobile: 'true',
  },
  {
    text: 'RF++',
    icon: 'k-i-tell-a-friend',
    'data-expanded': false,
    id: 24,
    page_title: 'RF++',
    element: <RFDashboard />,
    route: '/smartrf/overview',
    layouts: 'TileLayout',
    is_drawer_item: 'true',
    isauth: false,
    is_mobile: 'true',
    iconsvg: RFIcon,
    AuthPermission: 'RF',
  },
  {
    text: 'Device Enrollment',
    parentid: 24,
    id: 23,
    icon: 'k-icon-action',
    route: '/smartrf/device-enrollment',
    layouts: 'CardLayout',
    element: <DeviceEnrollment />,
    page_title: 'Device Enrollment',
    is_drawer_item: 'true',
    isauth: true,
    is_mobile: 'true',
    iconsvg: DeviceEnrollmentIcon,
  },
  {
    text: 'Profiles',
    icon: 'k-i-minus',
    id: 28,
    parentid: 24,
    route: '/smartrf/profile-groups',
    layouts: 'CardLayout',
    element: <RFProfileGroup />,
    page_title: 'Profile Group',
    is_drawer_item: 'true',
    isauth: false,
    is_mobile: 'true',
    iconsvg: ProfileIcon,
  },
  {
    text: 'Add Profile Group',
    icon: 'k-i-minus',
    id: 31,
    parentid: 24,
    route: '/smartrf/profile-group',
    layouts: 'TileLayout',
    element: <AddRFProfileGroup />,
    page_title: 'Add Profile Group',
    is_drawer_item: 'false',
    isauth: true,
    is_mobile: 'true',
  },
  {
    text: 'Add Profile',
    icon: 'k-i-minus',
    id: 29,
    parentid: 24,
    route: '/smartrf/profile',
    layouts: 'TileLayout',
    element: <AddRFProfile />,
    page_title: 'Add Profile',
    is_drawer_item: 'false',
    isauth: true,
    is_mobile: 'true',
  },
  {
    text: 'Form Info',
    icon: 'k-i-minus',
    id: 32,
    parentid: 24,
    route: '/smartrf/form-infos',
    layouts: 'CardLayout',
    element: <RFFormInfo />,
    page_title: 'Form Info',
    is_drawer_item: 'true',
    isauth: true,
    is_mobile: 'true',
    iconsvg: FormInfoIcon,
  },
  {
    text: 'Add Form Info',
    icon: 'k-i-minus',
    id: 33,
    parentid: 24,
    route: '/smartrf/form-info',
    layouts: 'TileLayout',
    element: <AddRFFormInfo />,
    page_title: 'Add Form Info',
    is_drawer_item: 'false',
    isauth: true,
    is_mobile: 'true',
  },
  {
    text: 'Field Info',
    icon: 'k-i-minus',
    id: 34,
    parentid: 24,
    route: '/smartrf/field-infos',
    layouts: 'CardLayout',
    element: <RFFieldInfo />,
    page_title: 'Field Info',
    is_drawer_item: 'true',
    isauth: true,
    is_mobile: 'true',
    iconsvg: FieldInfoIcon,
  },
  {
    text: 'Add Field Info',
    icon: 'k-i-minus',
    id: 35,
    parentid: 24,
    route: '/smartrf/field-info',
    layouts: 'TileLayout',
    element: <AddRFFieldInfo />,
    page_title: 'Add Field Info',
    is_drawer_item: 'false',
    isauth: true,
    is_mobile: 'true',
  },
  {
    text: 'Organization',
    icon: 'k-i-tell-a-friend',
    'data-expanded': false,
    id: 30,
    route: '/organization',
    page_title: 'Organization',
    layouts: 'TileLayout',
    element: <Organization />,
    is_drawer_item: 'true',
    isauth: true,
    is_mobile: 'true',
    iconsvg: OrganizationIcon,
    AuthPermission: 'OrganizationUser',
  },
  {
    text: 'Products',
    icon: 'k-i-minus',
    id: 36,
    parentid: 30,
    route: '/organization-products',
    layouts: 'CardLayout',
    element: <OrganizationProducts />,
    page_title: 'Products',
    is_drawer_item: 'true',
    isauth: true,
    is_mobile: 'true',
    iconsvg: ProductsIcon,
    AuthPermission: 'OrganizationUser',
  },
  {
    text: 'Users',
    icon: 'k-i-minus',
    id: 37,
    parentid: 30,
    route: '/organization-users',
    layouts: 'TileLayout',
    element: <OrganizationUser />,
    page_title: 'Users',
    is_drawer_item: 'true',
    isauth: true,
    is_mobile: 'true',
    iconsvg: UserIcon,
    AuthPermission: 'OrganizationUser',
  },
  {
    text: 'Redirect',
    route: '/AzureAdAuth/Callback',
    element: <AzureRedirectComponent />,
    layouts: 'EmptyLayout',
    is_drawer_item: 'false',
    is_mobile: 'false',
  },
  {
    id: 38,
    text: 'Password Change',
    icon: '',
    route: '/change-password',
    element: <PasswordChange />,
    layouts: 'TileLayout',
    is_drawer_item: 'false',
    page_title: 'Password Change',
    isauth: true,
    is_mobile: 'true',
    iconsvg: ProfileIcon,
  },
  {
    text: 'Redirect',
    route: '/SocialMediaAuth/CallBack',
    element: <SocialMediaRedirectComponent />,
    layouts: 'EmptyLayout',
    is_drawer_item: 'false',
    is_mobile: 'false',
  },
  {
    separator: true,
    is_drawer_item: 'true',
  },
  {
    text: 'Admin',
    icon: 'k-i-tell-a-friend',
    ['data-expanded']: false,
    id: 39,
    page_title: 'Admin',
    layouts: 'CardLayout',
    is_drawer_item: 'true',
    isauth: false,
    is_mobile: 'true',
    iconsvg: RFIcon,
    AuthPermission: AUTH_PERMISSION?.Admin,
  },
  {
    text: 'Organizations',
    icon: 'k-i-minus',
    id: 40,
    parentid: 39,
    route: '/admin/organizations',
    layouts: 'CardLayout',
    element: <AdminOrganization />,
    page_title: 'Organizations',
    is_drawer_item: 'true',
    isauth: true,
    is_mobile: 'true',
    iconsvg: FieldInfoIcon,
    AuthPermission: AUTH_PERMISSION?.Admin,
  },
  {
    text: 'Add Organization',
    icon: 'k-i-minus',
    id: 41,
    parentid: 39,
    route: '/admin/organization',
    layouts: 'TileLayout',
    element: <AddOrganization />,
    page_title: 'Add Organization',
    is_drawer_item: 'false',
    isauth: true,
    is_mobile: 'true',
    AuthPermission: AUTH_PERMISSION?.Admin,
  },
];

const RoutesItems = RoutesItemsList;

export default RoutesItems;
