import { DEVICE_TYPES, getItem, setItem } from 'smart-react';

/**
 * Return layout according to device dimensions
 * @param {string} tilelayout
 * @param {Object} deviceInfo
 * @returns {Object} returns device layout
 */
export const getLayout = ({ tiles, tilelayout, deviceInfo, layout }) => {
  let device = '';
  if (deviceInfo?.type === DEVICE_TYPES.PHONE) device = 'MobileScreenLayout';
  else if (deviceInfo?.type === DEVICE_TYPES.TABLET) {
    if (window.innerWidth >= 800 && window.innerWidth <= 1025)
      device = 'MobileScreenLayout';
    else device = 'TabletScreenLayout';
  } else device = 'LargeScreenLayout';

  const storedLayout = getItem(tilelayout) ?? [];

  // Merging stored layout with default for each tile
  const finalLayout = tiles?.map((tile, index) => {
    const storedTileLayout = storedLayout?.find((l) => l.id === tile.id);

    // Fetch individual card layout if available, else fallback to default layout or stored layout
    const specificLayout =
      storedTileLayout ||
      tile?.layout?.[device][0] || // Use tile-specific layout if defined
      layout[device][index] ||
      layout[device][0]; // Default layout

    return {
      id: tile.id,
      ...specificLayout,
      order: storedTileLayout?.order ?? index, // Ensure order is from stored layout or fallback to index
      col:
        storedTileLayout?.col ??
        layout[device][index]?.col ??
        layout[device][0]?.col, // Merge col positions
    };
  });

  return finalLayout;
};

/**
 * Get Device Type
 * @param {Object} deviceInfo
 * @returns {string} returns device Type
 */
const getMediaType = (deviceInfo) => {
  if (
    deviceInfo?.type === DEVICE_TYPES.TABLET ||
    deviceInfo?.type === DEVICE_TYPES.PHONE
  ) {
    return 'Tablet';
  }
  return 'laptop';
};

/**
 * Save Layout in local storage
 * @param {string} tilelayout
 * @param {Object} data array of javascript object
 * @param {boolean} allowReposition
 * @param {func} setAllowReposition
 * @param {Object} deviceInfo
 * @returns {void}
 */
export const saveLayout = ({
  tilelayout,
  data,
  allowReposition,
  setAllowReposition,
  deviceInfo,
}) => {
  if (allowReposition) {
    setItem(tilelayout, data);
    setItem(getMediaType(deviceInfo));
  }
  setAllowReposition(!allowReposition);
};

export const handleReposition = (event, setData) => {
  const updatedData = event.value.map((tile) => {
    const minColSpan = tile.layout?.minColSpan || 1;
    const minRowSpan = tile.layout?.minRowSpan || 1;
    return {
      ...tile,
      colSpan: Math.max(tile.colSpan, minColSpan),
      rowSpan: Math.max(tile.rowSpan, minRowSpan),
    };
  });

  setData(updatedData);
};
