// src/utils/urlHelpers.js

/**
 * Double encodes a URL parameter to handle automatic decoding on the backend.
 * @param {string} value - The value to be double encoded.
 * @returns {string} - The double-encoded value.
 */
export function doubleEncode(value) {
    return encodeURIComponent(encodeURIComponent(value));
}
