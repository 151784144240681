import {
  USER_TENANTS,
  SMART_IS_EMAIL,
  AUTH_PERMISSION,
  USER_DATA,
  ROLES,
  DEFAULT_TENANT,
} from '../../constants/applicationConstants';
/**
 * it check whether this item tenant_id exist is local storage or not and return true false
 * @param {object} item
 * @returns {boolean}
 */
export const ValidatePermissions = (item) => {
  const userTenants = JSON.parse(localStorage.getItem(USER_TENANTS));
  let filterTenants = userTenants.filter(
    (userTenant) =>
      (userTenant.TenantId.toLowerCase() === item?.tenant_id?.toLowerCase() ||
        userTenant.TenantId.toLowerCase() === item?.TenantId?.toLowerCase() ||
        userTenant.TenantId.toLowerCase() === item?.tenantId?.toLowerCase()) &&
      userTenant.IsWrite === true,
  );
  if (filterTenants.length > 0) {
    return true;
  }
  return false;
};
/**
 * find the tenant from local storage aganist this item
 * @param {object} item
 * @returns {object}
 */
export const findTenant = (item) => {
  let tempTenant = JSON.parse(localStorage.getItem(USER_TENANTS)).filter(
    (userTenant) =>
      userTenant.TenantId.toLowerCase() === item?.tenant_id?.toLowerCase() ||
      userTenant.TenantId.toLowerCase() === item?.TenantId?.toLowerCase() ||
      userTenant.TenantId.toLowerCase() === item?.tenantId?.toLowerCase(),
  )[0];
  return tempTenant;
};
/**
 * find the latest subscription object from all the subscriptions
 * @param {Array} subscriptions
 * @returns {object}
 */
export const getSubscription = (subscriptions) => {
  if (
    !subscriptions ||
    !Array.isArray(subscriptions) ||
    subscriptions.length === 0
  ) {
    return null; // Handle invalid input
  }

  let latestSubscription = subscriptions[0];
  let latestSubscriptionDate = latestSubscription?.SubscriptionEndDate;

  for (let i = 1; i < subscriptions.length; i += 1) {
    const currentSubscription = subscriptions[i];
    const currentDate = currentSubscription?.SubscriptionEndDate;

    // Compare the date strings directly
    if (currentDate > latestSubscriptionDate) {
      latestSubscriptionDate = currentDate;
      latestSubscription = currentSubscription;
    }
  }
  // return latest permission object
  return latestSubscription;
};

/**
 * check feature is expire or not
 * @param {String} dateString
 * @returns {boolean}
 */
export const isFeatureExpired = (dateString) => {
  const inputDate = new Date(dateString);
  const today = new Date();

  today.setHours(0, 0, 0, 0); // Reset time for accurate comparison

  return inputDate < today;
};
/**
 * check feature is Subscribe or not
 * @param {object} feature
 * @returns {boolean}
 */
export const isFeatureSubscribed = (feature) => {
  // Check if Permissions object exists
  if (!feature?.Permissions || feature?.Permissions?.length === 0) {
    return false;
  }
  const latestPermission = getSubscription(feature?.Permissions);
  // Check if the feature_id matches the FeatureId in Permissions
  return (
    latestPermission?.FeatureId === feature?.feature_id &&
    !isFeatureExpired(latestPermission?.SubscriptionEndDate)
  );
};
/**
 * check feature name is subscribe in products or not
 * @param {Array} products
 * @param {String} feature_name
 * @returns {boolean}
 */
export const checkFeatureSubscription = (products, feature_name) =>
  products?.some((product) =>
    product?.features?.some(
      (feature) =>
        feature.name === feature_name && !isFeatureSubscribed(feature),
    ),
  );

/**
 * check all product features is subscribe or not
 * @param {object} product
 * @returns {boolean}
 */
export const isAllProductFeaturesSubscribed = (product) => {
  // Check if product and features array exist
  if (
    !product ||
    !product.features ||
    !Array.isArray(product.features) ||
    product.features.length === 0
  ) {
    return false;
  }

  // Check each feature has valid permissions
  return product.features.every((feature) => {
    // Check if Permissions exists and is not null
    if (!feature?.Permissions || feature?.Permissions?.length === 0) {
      return false;
    }
    const latestPermission = getSubscription(feature?.Permissions);
    // Check if Permission is active
    return latestPermission.Active === true;
  });
};
/**
 * check all features is Expired or not
 * @param {Array} features
 * @returns {boolean}
 */
export const isAllProductFeaturesExpired = (features) => {
  // Filter out features that don't have Permissions
  const featuresWithPermissions = features.filter(
    (feature) => feature?.Permissions?.length > 0,
  );

  // If no features have permissions, return false since we can't determine expiry
  if (featuresWithPermissions?.length === 0) {
    return false;
  }

  // Check if all features with permissions are expired using isFeatureExpired function
  return featuresWithPermissions.every((feature) => {
    const latestPermission = getSubscription(feature?.Permissions);
    return isFeatureExpired(latestPermission?.SubscriptionEndDate);
  });
};
/**
 * check that if any features of product is expire or not
 * @param {Array} features
 * @returns {boolean}
 */
export const isAnyProductFeatureExpired = (features) => {
  // Filter out features that don't have Permissions
  const featuresWithPermissions = features.filter(
    (feature) => feature?.Permissions?.length > 0,
  );

  // If no features have permissions, return false since we can't determine expiry
  if (featuresWithPermissions.length === 0) {
    return false;
  }

  // Check if any feature with permissions is expired using isFeatureExpired function
  return featuresWithPermissions.some((feature) => {
    const latestPermission = getSubscription(feature?.Permissions);
    return isFeatureExpired(latestPermission?.SubscriptionEndDate);
  });
};
/**
 * Checks if the product features array has at least one feature with valid permissions
 * @param {Array} features - Array of product features
 * @returns {boolean} - True if at least one feature has permissions, false otherwise
 */
export const hasFeaturePermissions = (features) => {
  // Check if features is an array and not empty
  if (!Array.isArray(features) || features.length === 0) {
    return false;
  }

  // Check if any feature has a valid Permissions object
  return features.some((feature) => {
    const latestPermission = getSubscription(feature?.Permissions);
    // Check if Permissions array exists and has items, and subscription is not expired
    return (
      feature?.Permissions?.length > 0 &&
      !isFeatureExpired(latestPermission?.SubscriptionEndDate)
    );
  });
};
/**
 * it check that any feature is valid permissions from all products features or not
 * @param {Array} products
 * @returns {boolean}
 */
export const hasAnyFeaturePermissions = (products) =>
  products.some((product) =>
    product.features.some((feature) => {
      const latestPermission = getSubscription(feature?.Permissions);
      return (
        feature?.Permissions?.length > 0 &&
        !isFeatureExpired(latestPermission?.SubscriptionEndDate)
      );
    }),
  );
/**
 * check is the Organization User or not
 * @param {object} obj
 * @returns {boolean}
 */
export const checkOrganizationUser = (obj) => {
  if (
    AUTH_PERMISSION.OrganizationUser === obj.AuthPermission &&
    !isAuthorizedTenant()
  ) {
    return false;
  }
  return true;
};
/**
 * check the role is exist in user or not
 * @param {Object} user
 * @returns {boolean}
 */
export const hasUserRole = (user, role) => {
  const rolesArray = user?.roles
    .split(',')
    .map((roleItem) => roleItem.split(':')[0].trim());
  return rolesArray?.includes(role);
};
/**
 * check the user is admin or not
 * @param {Object} user
 * @returns {boolean}
 */
export const isAdminRole = (user) => {
  const configRole = JSON.parse(localStorage.getItem(ROLES?.Admin))
    ?.Value?.split(',')
    .map((item) => item.trim());
  const rolesArray = user?.roles
    ?.split(',')
    ?.map((roleItem) => roleItem?.split(':')[0]?.trim());
  return rolesArray?.some((itemB) => configRole?.includes(itemB));
};

/**
 * check the user is organization admin or not
 * @param {Object} user
 * @returns {boolean}
 */
export const isOrganizationAdminRole = (user) => {
  const adminRoles = JSON.parse(localStorage.getItem(ROLES?.Admin))
    ?.Value?.split(',')
    ?.map((item) => item.trim());
  const companyAdminRoles = JSON.parse(
    localStorage.getItem(ROLES?.OrganizationAdmin),
  )
    ?.Value?.split(',')
    ?.map((item) => item.trim());
  const mergeArray = [...adminRoles, ...companyAdminRoles];
  const rolesArray = user?.roles
    .split(',')
    ?.map((roleItem) => roleItem?.split(':')[0]?.trim());
  return rolesArray?.some((itemB) => mergeArray?.includes(itemB));
};

/**
 * Check the User has admin role or not
 * @param {object} obj
 * @returns {boolean}
 */
export const checkAdminUser = (obj) => {
  const userData = JSON.parse(sessionStorage.getItem(USER_DATA));
  if (AUTH_PERMISSION.Admin == obj.AuthPermission && !isAdminRole(userData)) {
    return false;
  }
  return true;
};

/**
 * check this is smart is email or not
 * @param {Object} user
 * @returns {boolean}
 */
export const isSmartIsEmail = (user) => {
  if (user && typeof user.name === 'string') {
    return user.name.toLowerCase().includes(SMART_IS_EMAIL);
  }
  return false;
};

/**
 * check parent route of this route has permission or not
 * @param {Object} obj
 * @param {Array} routes
 * @returns {boolean}
 */
export const checkParentRoutePermission = (obj, routes) => {
  if (obj?.parentid) {
    const matchingObject = routes.find((temp) => temp.id === obj?.parentid);
    if (
      matchingObject &&
      matchingObject?.AuthPermission &&
      AUTH_PERMISSION[matchingObject?.AuthPermission] &&
      AUTH_PERMISSION[matchingObject?.AuthPermission] ===
        ROLES[matchingObject?.AuthPermission] &&
      !hasUserRole(
        JSON.parse(sessionStorage.getItem(USER_DATA)),
        ROLES[matchingObject?.AuthPermission],
      )
    ) {
      return false;
    }
  }

  return true;
};
/**
 * get the user default tenant
 * @returns {object}
 */
export const getDefaultTenant = () =>
  JSON.parse(localStorage.getItem(DEFAULT_TENANT));

/**
 * get the authorized tenant list
 * @returns {Array}
 */
export const getAuthorizedTenants = () =>
  JSON.parse(localStorage.getItem(USER_TENANTS)).filter(
    (userTenant) =>
      userTenant.IsWrite === true && userTenant.IsOrganization === true,
  );

/**
 * check the user has any authorized tenant or not
 * @returns {object}
 */
export const isAuthorizedTenant = () => {
  const userTenants = JSON.parse(localStorage.getItem(USER_TENANTS));
  return (
    userTenants?.some(
      (userTenant) =>
        userTenant.IsWrite === true && userTenant.IsOrganization === true,
    ) || false
  );
};
/**
 * check this is personal tenant or not
 * @param {String} tenant_id
 * @returns {boolean}
 */
export const isPersonalTenant = (tenant_id) => {
  const userTenants = JSON.parse(localStorage.getItem(USER_TENANTS));
  return (
    userTenants?.some(
      (userTenant) =>
        userTenant.TenantId.toLowerCase() === tenant_id?.toLowerCase() &&
        userTenant.IsOrganization === false,
    ) || false
  );
};
/**
 * find the tenant by tenant_id
 * @param {String} tenant_id
 * @returns {boolean}
 */
export const findTenantById = (tenant_id) => {
  let userTenants = JSON.parse(localStorage.getItem(USER_TENANTS));

  // Use return to ensure the filtered result is returned
  return userTenants.filter(
    (userTenant) =>
      userTenant.TenantId.toLowerCase() === tenant_id?.toLowerCase(),
  )[0];
};

/**
 * get all tenant list
 * @returns {object}
 */
export const getAllTenant = () =>
  JSON.parse(localStorage.getItem(USER_TENANTS));
/**
 * get the user personal tenant
 * @returns {object}
 */
export const getPersonalTenant = () =>
  JSON.parse(localStorage.getItem(USER_TENANTS))?.filter(
    (userTenant) => userTenant.IsOrganization === false,
  )[0];

/**
 * check the tenant_id is world tenant id or not
 * @param {String} tenant_id
 * @returns {boolean}
 */
export const isWorldTenant = (tenant_id) => {
  const userTenants = JSON.parse(localStorage.getItem(USER_TENANTS));
  const tenant = userTenants.find(
    (org) => org.TenantId.toLowerCase() === tenant_id.toLowerCase(),
  );
  return tenant ? tenant.TenantName === 'DEFAULT' : false;
};

export default ValidatePermissions;
